<template>
  <a-modal :title="ModalTitle" v-model="visible"
    cancelText="取消" okText="保存" width="850px"
    @ok="handleOk" @cancel="handleCancel" :footer="null">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-table size="small" :pagination="false" :bordered='false' rowKey="book_id"
      :columns="columns" :dataSource="list" @change="handleChange">

      <template slot="user" slot-scope="text, record">
          <LUser :data="record"/>
      </template>

       <template slot="book_status" slot-scope="text,record,index">
          <a-radio-group :value="record.book_status" @change="handleRadio(index,record)" button-style="solid" size="small">
            <a-radio :value="1" disabled>
              待上课
            </a-radio>
            <a-radio :value="2">
              已到课
            </a-radio>
            <a-radio :value="0">
              已请假
            </a-radio>
          </a-radio-group>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
  { title: '学员名称', dataIndex: 'student_name', key: 'student_name' ,scopedSlots: { customRender: 'user' }},
  { title: '上课状态', dataIndex: 'book_status', key: '2',align:'center' , scopedSlots: { customRender: 'book_status' },sorter:()=>{}},
  { title: '课时消耗', dataIndex: 'book_costs', key: 'book_costs',align:'center'},
]
export default {
  name: 'StudentsModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      columns,
      ModalTitle: '上课学员',
      loading:false,
      visible: false,
      list: [],
      searchParams: {
          "search": {},
          "sort": ''
      },
    }
  },

  created() {
    this.visible = true
    this.getList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList() {
      this.loading = true
      const { course_class_id } = this.item
      this.searchParams.course_class_id = course_class_id
      let res = await this.$store.dispatch('courseScheduleDetailAction', { data: this.searchParams })
      this.list = res.items
      this.loading = false
    },
    handleChange(pagination, filters, sorter) {
      if(sorter.order){
        if(sorter.order === "ascend"){
          this.searchParams['sort'] = `${sorter.field}`
        }else{
          this.searchParams['sort'] = `-${sorter.field}`
        }
      }else{
        this.searchParams['sort'] = ''
      }
      this.getList()
    },
    async mutilBook() {
      this.loading = true
      let res = await this.$store.dispatch('absenceSigninCheckinAction', { data: { book_id: this.selectedRowKeys } })
      if (res) {
        this.$message.success('操作成功!')
        this.selectedRowKeys = []
        this.getList()
      }
    },
    async mutilUnBook() {
      this.loading = true
      let res = await this.$store.dispatch('absenceSigninCancelAction', { data: { book_id: this.selectedRowKeys } })
      if (res) {
        this.$message.success('操作成功!')
        this.selectedRowKeys = []
        this.getList()
      }
    },
    handleRadio(index,record){
      console.log(record.book_status,record)
      this.selectedRowKeys = [record.book_id]
      if(record.book_status == 0){
        this.mutilBook()
      }else{
        this.mutilUnBook()
      }
    },
    handleOk(){

    },
    handleCancel(){
      this.parent.hideStudentsModal(0)
    }
  }
}
</script>