<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <!-- <a-form-item>
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item> -->
          <a-form-item>
            <a-button type="primary" icon="check" @click="confirmItem()">批量同意</a-button>
          </a-form-item>
          <a-form-item>
            <a-button icon="close" @click="refuseItem()">批量拒绝</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>员工名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.realname" placeholder="员工名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属校区</span>
              </template>
              <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择校区" showSearch :filterOption="filterOption" style="width: 210px">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>操作状态</span>
              </template>
              <a-select allowClear v-model="searchData.opt_status" placeholder="请选择操作状态" style="width: 120px">
                <a-select-option v-for="(d, index) of optStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="apply_id"
        :selection="{key: 'key'}"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="images" slot-scope="text, record">
          <LImg :preview="0" v-for="(image, index) of record.apply_images" :key="index"  
            :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
        </template>

        <template slot="status" slot-scope="text,record">
            <a-switch checkedChildren="同意" unCheckedChildren="拒绝" :checked="text=== 1" @change="changeStatus(record)"/>
        </template>

        <template slot="classDate" slot-scope="text, record">
          <a href="javascript:;">
            {{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}
          </a>
        </template>

        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="confirmItem(record)">同意</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="refuseItem(record)">拒绝</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '所属校区', dataIndex: 'studio_name', key: '4' },
  { title: '所在班级', dataIndex: 'class_name', key: '3' },
  { title: '请假员工', width: '100px', dataIndex: 'employee_name', key: 'name' },
  { title: '上课时间', dataIndex: 'class_start',scopedSlots: { customRender: 'classDate' } , key: '20'},
  { title: '请假课程', dataIndex: 'course_name', key: '1' },
  { title: '申请理由', width: 100, dataIndex: 'apply_text', key: '5' },
  { title: '申请备注图片', dataIndex: 'apply_images', key: '6', scopedSlots: { customRender: 'images' } },
  { title: '申请时间', dataIndex: 'created_at', key: '8' },
  { title: '申请状态', dataIndex: 'status', key: '7' ,align:"right", scopedSlots: { customRender: 'status' }},
  // { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'
import authority from '@/common/mixins/authority'
import moment from 'moment'

export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      optStatus: C_ITEMS.optStatus,
      studios: [],

      searchData: {},

      visible: false,

      selectedRowKeys: [],
      authType:['education','absence'],
    }
  },
  mixins: [ authority ],
  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    this.getList()
    this.getStudio()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('absenceApplyAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    reset() {
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },
    changeStatus(record) {
      if (record.status === 1) {
        this.refuseItem(record)
      } else {
        this.confirmItem(record)
      }
    },
    confirmItem(item) {
      let that = this
      let apply_id = []
      if (!item) {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
          apply_id = this.selectedRowKeys
        }
      } else {
        apply_id = [item.apply_id]
      }
      this.$confirm({
        title: `您确定请假申请吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('absenceApplyConfirmAction', { data: { apply_id } })
            if (res) {
              if (!item) {
                that.selectedRowKeys = []
              }
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    refuseItem(item) {
      let that = this
      let apply_id = []
      if (!item) {
        if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
          apply_id = this.selectedRowKeys
        }
      } else {
        apply_id = [item.apply_id]
      }
      this.$confirm({
        title: `您确定要拒绝请假申请吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('absenceApplyRefuseAction', { data: { apply_id } })
            if (res) {
              if (!item) {
                that.selectedRowKeys = []
              }
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
  }
}
</script>
