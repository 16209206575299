<template>
  <a-modal title="批量调整" v-model="visible"
    cancelText="取消" okText="保存" width="31.25%"  :confirmLoading="confirmLoading"
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form">
          <a-form-item :label="item.label" v-for="(item, index) of formData" :key="index">
            <a-select placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" :allowClear="!item.rules[0].required">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item class="block-line" label="授课老师" >
            <a-select allowClear v-model="teacher_id" placeholder="请选择" style="width: 100%">
                <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
            </a-select>
          </a-form-item> -->
        </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'room_id',
        label: '上课教室',
        type: 'select',
        rules: [{ required: false, message: '请选择上课教室!' }],
        items: {
            data: 'rooms',
            label: 'filter_name',
            value: 'room_id'
        }
    },
    {
        name: 'teacher_id',
        label: '授课老师',
        type: 'select',
        rules: [{ required: false, message: '请选择授课老师!' }],
        items: {
            data: 'teachers',
            label: 'filter_name',
            value: 'teacher_id'
        }
    },
    {
        name: 'class_mode',
        label: '课程模式',
        type: 'select',
        rules: [{ required: false}],
        items: {
            data: 'classModes',
            label: 'label',
            value: 'value'
        }
    },

]
import C_ITEMS from '@/utils/items'

export default {
  name: 'adjustModal',
  inject: ['parent'],
  props: {
    item: Array,
    teachers: Array,
  },

  data() {
    return {
      loading:false,
      confirmLoading:false,
      visible: false,
      room_id:'',
      teacher_id:'',
      seleteItems:{
        rooms:[],
        teachers:this.teachers,
        classModes: C_ITEMS.classModes,
      },
      formData,
      form: this.$form.createForm(this),
    }
  },

  created() {
    this.visible = true
    this.getRoom()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getRoom() {
        let res = await this.$store.dispatch('searchStudioRoomAction', {})
        this.seleteItems.rooms = res.data
    },
    async handleOk(){
      try {
        const { item, form } = this
        let params = await form.validateFields()
        params.course_class_id = item
        this.confirmLoading = true
        await this.$store.dispatch('courseScheduleSubstituteAction', {data:params})
        .then(res=>{
          this.confirmLoading=false;
        })
        this.parent.hideAdjustModal(1)
      }catch{

      }
    },
    handleCancel(){
      this.parent.hideAdjustModal(0)
    }
  }
}
</script>