<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员姓名/手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.phone" placeholder="客户姓名/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="上课校区" >
                      <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="课程名称" >
                      <a-select allowClear v-model="searchData.course_id" placeholder="请选择">
                       <a-select-option v-for="(d, index) of courses" :key="index" :value="d['course_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="授课老师" >
                      <a-select allowClear v-model="searchData.teacher_id" placeholder="请选择">
                        <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="上课日期" >
                      <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.class_date" :placeholder="['开始时间', '结束时间']"/>
                    </a-form-item>
                    <a-form-item class="block-line" label="课程模式" >
                      <a-select allowClear v-model="searchData.class_mode" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classModes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="book_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1400 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="images" slot-scope="text, record">
          <LImg :preview="0" v-for="(image, index) of record.cancel_image" :key="index"  
            :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
        </template>
        <template slot="classDate" slot-scope="text, record">
          <span>{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</span>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '上课校区', width: '120px', dataIndex: 'studio_id', key: 'name', fixed: 'left' ,ellipsis:true},
  { title: '班级名称', dataIndex: 'classes_name', key: '1' , fixed: 'left' ,ellipsis:true},
  { title: '上课时间', dataIndex: 'class_start', key: 'class_start', scopedSlots: { customRender: 'classDate' } , fixed: 'left' ,sorter:()=>{}},
  { title: '课程名称', dataIndex: 'course_name', key: '2' ,ellipsis:true},
  { title: '授课老师', dataIndex: 'teacher_name', key: '3' },
  { title: '学员名称', dataIndex: 'student_name', key: '6' },
  { title: '请假理由', dataIndex: 'cancel_reason', key: '9' },
  { title: '请假详情', dataIndex: 'cancel_remark', key: '10' },
  { title: '请假凭证', dataIndex: 'cancel_image', key: '11', scopedSlots: { customRender: 'images' } },
  { title: '请假时间', dataIndex: 'created_at', key: '12' }
]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges";
import authority from '@/common/mixins/authority'
import moment from 'moment'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      classModes: C_ITEMS.classModes,
      studios: [],
      courses: [],
      teachers: [],

      searchData: {},
      authType:['education','absence'],
      visible: false
    }
  },
  mixins: [ ranges , authority ],
  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    this.getList()
    this.getStudio()
    this.getCourses()
    this.getTeacher()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCourses() {
      let res = await this.$store.dispatch('searchCourseAction', {})
      this.courses = res.data
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('absenceCancelAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    reset() {
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },
    async toExport(){
      this.exportLoading = true
      let obj = {}
      let { searchData } = this
      for (let k in searchData) {
        if (k === 'class_date') {
          obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
          obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
        } else {
          obj[k] = searchData[k]
        }
      }
      let exportData = {
          search:obj,
          sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('absenceCancelExportAction', exportData)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `学员缺勤记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    },
  }
}
</script>
