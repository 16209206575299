<template>
  <a-modal title="请选择顺延天数" v-model="visible"
    cancelText="取消" okText="保存" width="31.25%"
    @ok="handleOk" @cancel="handleCancel">
        <a-input-number style='width:100%' @change="onChange" v-model="nextTime" :min="1"/>
  </a-modal>
</template>

<script>
export default {
  name: 'timeModal',
  inject: ['parent'],
  props: {
    item: Array
  },

  data() {
    return {
      loading:false,
      visible: false,
      nextTime:1,
    }
  },

  created() {
    this.visible = true
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk(){
        let res = await this.$store.dispatch('courseScheduleNextdayAction', { selection: this.item,next_days: this.nextTime})
        this.parent.hideTimeModal(1)
    },
    handleCancel(){
      this.parent.hideTimeModal(0)
    },
    onChange(e){
      console.log(e)
      this.nextTime = e || 1
    }
  }
}
</script>