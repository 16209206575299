<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>上课/请假记录</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">课程排期<a-badge :count="0" /></span>
          <kcpq v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">学员上课记录<a-badge :count="0" /></span>
          <xyskjl v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">学员请假记录<a-badge :count="0" /></span>
          <xyqjjl v-if="activeKey==='3'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">老师请假申请<a-badge :count="0" /></span>
          <lsqjsq v-if="activeKey==='4'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import kcpq from './kcpq/index'
import xyskjl from './xyskjl/index'
import xyqjjl from './xyqjjl/index'
import lsqjsq from './lsqjsq/index'

export default {
  components: {
    kcpq,
    xyskjl,
    xyqjjl,
    lsqjsq,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
