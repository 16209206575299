<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号</span>
              </template>
              <a-input allowClear v-model.trim="searchData.phone" placeholder="客户姓名/手机号" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="上课校区" >
                      <a-select allowClear v-model="searchData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择" showSearch :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="所属班级" >
                      <a-select allowClear showSearch v-model="searchData.class_id" :filterOption="filterOption" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classes" :key="index" :value="d['class_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>  
                    <a-form-item class="block-line" label="课程科目" >
                      <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="授课老师" >
                      <a-select allowClear v-model="searchData.teacher_id" placeholder="请选择">
                        <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>  
                    <a-form-item class="block-line" label="上课教室" >
                      <a-select allowClear v-model="searchData.room_id" placeholder="请选择">
                        <a-select-option v-for="(item, index) of rooms" :key="index" :value="item.room_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>  
                    <a-form-item class="block-line" label="上课日期" >
                      <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" v-model="searchData.class_date" :placeholder="['开始时间', '结束时间']"/>
                    </a-form-item>
                    <a-form-item label="课程状态" >
                      <a-select allowClear v-model="searchData.class_status" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="课程模式" >
                      <a-select allowClear v-model="searchData.class_mode" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classModes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="book_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1400 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" v-if="record.book_status==='已请假'" @click="checkinItem(record)">学员签到</a>
          <a href="javascript:;" v-else @click="cancelItem(record)">学员请假</a>
        </template>

        <template slot="classDate" slot-scope="text, record">
          <span>{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</span>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '上课校区', width: '120px', dataIndex: 'studio_id', key: 'name', fixed: 'left' ,ellipsis:true},
  { title: '班级名称', dataIndex: 'classes_name', key: '1' , fixed: 'left' },
  { title: '上课时间', dataIndex: 'class_start', key: 'class_start', scopedSlots: { customRender: 'classDate' } ,align:"right", fixed: 'left' ,sorter:()=>{}},
  { title: '学员名称', dataIndex: 'student_name', key: '6' },
  { title: '课程名称', dataIndex: 'course_name', key: '2' },
  { title: '上课教室', dataIndex: 'room_id', key: '3' },
  // { title: '课消金额', dataIndex: 'log', key: '8' },
  { title: '消耗课时', dataIndex: 'book_costs', key: '9',align:"center"},
  { title: '课程状态', dataIndex: 'book_status', key: '10', fixed: 'center' },
  { title: '操作', key: 'operation', fixed: 'right', width: 74, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'
import ranges from "@/common/mixins/ranges";
import authority from '@/common/mixins/authority'
import moment from 'moment'
export default {
  name: 'gjjl',

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      classStatus: C_ITEMS.classStatus,
      classModes: C_ITEMS.classModes,
      studios: [],
      classCategorys: [],
      teachers: [],
      rooms: [],
      classes: [],

      searchData: {},
      authType:['education','absence'],
      visible: false
    }
  },
  mixins: [ ranges , authority ],
  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    this.getList()
    this.getStudio()
    this.getClassCategory()
    this.getTeacher()
    this.getRoom()
    this.getClasses()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    async getRoom() {
      let res = await this.$store.dispatch('searchStudioRoomAction', {})
      this.rooms = res.data
    },
    async getClasses() {
      let res = await this.$store.dispatch('searchClassesAction', {})
      this.classes = res.data
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('absenceSigninAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading =false
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    reset() {
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },
    cancelItem(item) {
      let that = this
      this.$confirm({
        title: `您确定该学员"${item.student_name}"请假？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('absenceSigninCancelAction', { data: { book_id: [item.book_id] } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    checkinItem(item) {
      let that = this
      this.$confirm({
        title: `您确定该学员"${item.student_name}"已到访？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('absenceSigninCheckinAction', { data: { book_id: [item.book_id] } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    async toExport(){
      this.exportLoading = true
      let obj = {}
      let { searchData } = this
      for (let k in searchData) {
        if (k === 'class_date') {
          obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
          obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
        } else {
          obj[k] = searchData[k]
        }
      }
      let exportData = {
          search:obj,
          sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('absenceSigninExportAction', exportData)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `学员上课记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    },
  }
}
</script>
