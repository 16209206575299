<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' v-if="selectedRowKeys.length == 0">
          <a-form-item class="block-line">
            <a-range-picker
            :ranges="rangesData"
            :value='rangesValue'
            :disabled-date="disabledDate" :allowClear="false"
            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
            @change="onChange" />
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>上课校区</span>
              </template>
              <a-select allowClear showSearch :filterOption="filterOption" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model="searchData.studio_id" placeholder="请选择校区" style="width: 220px">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属班级</span>
              </template>
              <a-select allowClear showSearch v-model="searchData.class_id" :filterOption="filterOption" placeholder="请选择所属班级" style="width: 200px">
                <a-select-option v-for="(d, index) of classes" :key="index" :value="d['class_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>课程名称</span>
              </template>
              <a-select allowClear v-model="searchData.course_id" placeholder="请选择课程名称" style="width: 200px">
                <a-select-option v-for="(d, index) of courses" :key="index" :value="d['course_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-popover placement="bottom" trigger="click" v-model="visible">
              <template slot="content">
                <div class="more-search">
                  <a-form layout='inline'>
                    <a-form-item class="block-line" label="授课老师" >
                      <a-select allowClear v-model="searchData.teacher_id" placeholder="请选择">
                        <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="课程顾问" >
                      <a-select allowClear v-model="searchData.cc_id" placeholder="请选择">
                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="课程状态" >
                      <a-select allowClear v-model="searchData.class_status" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item label="课后反馈" >
                      <a-select allowClear v-model="searchData.is_trate" placeholder="请选择">
                        <a-select-option v-for="(d, index) of isTrates" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item class="block-line" label="课程模式" >
                      <a-select allowClear v-model="searchData.class_mode" placeholder="请选择">
                        <a-select-option v-for="(d, index) of classModes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-form>
                  <div class="more-search__buttons">
                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                    <a-button @click="reset">重置</a-button>
                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                  </div>
                </div>
              </template>
              <a-button>更多<a-icon type="down" /></a-button>
            </a-popover>
          </a-form-item>
        </a-form>
        <a-form layout='inline' v-else>
            <a-form-item>
                已选择<span>{{selectedRowKeys.length}}</span>项
            </a-form-item>
            <a-form-item>
                <a-divider type="vertical" />
            </a-form-item>
            <a-form-item>
                <a-button @click="showAdjustModal">批量调课</a-button>
            </a-form-item>
            <a-form-item>
                <a-button @click="showTimeModal">批量顺延</a-button>
            </a-form-item>
            <a-form-item>
                <a-button @click="toCancel">取消排课</a-button>
            </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="course_class_id"
        :selection="{key: 'course_class_id'}" @change="handleChange"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :columns="columns" :dataSource="list" :scroll="{ x: 1300 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="reserve_count" slot-scope="text, record">
          <a href="javascript:;" @click="showStudentsModal(record)">
            <span>{{record.signin_count}}/{{record.cancel_count}}</span>
          </a> 
        </template>

        <template slot="classDate" slot-scope="text, record">
          <a href="javascript:;" @click="showStudentsModal(record)">{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</a>
        </template>

        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="showStudentsModal(record)">上课学员</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="showTeacherModal(record)">代课老师</a>
          <!-- <a-divider type="vertical" />
          <a href="javascript:;">取消课程</a> -->
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <studentsModal v-if="studentsVisible" :item="modalData"/>
    <substituteModal v-if="teacherVisible" :item="modalData"/>
    <timeModal v-if="isNextDay" :item="selectedRowKeys"/>
    <adjustModal v-if="isAdjustModal" :item="selectedRowKeys" :teachers="teachers" />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '课程时间', dataIndex: 'class_start',scopedSlots: { customRender: 'classDate' } , fixed: 'left', key: '5' ,sorter:()=>{}},
  { title: '上课校区', width: '120px', dataIndex: 'studio_id', key: 'name', fixed: 'left' ,sorter:()=>{}},
  { title: '班级名称', dataIndex: 'class_id', key: '1' , fixed: 'left'},
  { title: '课程名称', dataIndex: 'course_id', key: 'course_id' ,sorter:()=>{}},
  { title: '授课老师', dataIndex: 'teacher_id', key: '3' ,sorter:()=>{}},
  { title: '上课教室', dataIndex: 'room_id', key: '4' ,sorter:()=>{}},
  { title: '到课/缺勤', dataIndex: 'reserve_count', key: '6', scopedSlots: { customRender: 'reserve_count' } ,align:"center" },
  { title: '单课消耗', dataIndex: 'class_costs', key: '7',align:"center" },
  { title: '全员课消', dataIndex: 'total_class_costs', key: '8',align:"center" },
  { title: '课程状态', dataIndex: 'class_status', key: '10',align:"center",sorter:()=>{}},
  { title: '操作', key: 'operation', fixed: 'right', width: 146, scopedSlots: { customRender: 'action' }}
]

import C_ITEMS from '@/utils/items'
import studentsModal from './studentsModal'
// import teacherModal from './teacherModal'

import substituteModal from './../../../classes/bjgl/detail/bjpk/substituteModal'

import timeModal from './timeModal'
import adjustModal from './adjustModal'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import moment from 'moment'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    studentsModal,
    teacherModal,
    substituteModal,
    timeModal,
    adjustModal
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      paramsData:{
        start_date:'',
        end_date:'',
      },
      list: [],
      current: 1,
      selectedRowKeys:[],
      classStatus: C_ITEMS.classStatus,
      isTrates: C_ITEMS.isTrates,
      classModes: C_ITEMS.classModes,
      studios: [],
      classes: [],
      courses: [],
      teachers: [],
      consultants: [],

      searchData: {},

      visible: false,
      isNextDay: false,
      isAdjustModal: false,
      modalData: {},
      studentsVisible: false,
      teacherVisible: false,
      authType:['education','absence'],
    }
  },
  mixins: [ ranges , authority , tableMixins ],
  created() {
    this.paramsData.start_date = moment().format('YYYY-MM-DD')
    this.paramsData.end_date = moment().format('YYYY-MM-DD')
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]

    this.getList()
    this.getStudio()
    this.getClasses()
    this.getCourses()
    this.getTeacher()
    this.getConsultant()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getClasses() {
      let res = await this.$store.dispatch('searchClassesAction', {})
      this.classes = res.data
    },
    async getCourses() {
      let res = await this.$store.dispatch('searchCourseAction', {})
      this.courses = res.data
    },
    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.start_date = this.paramsData.start_date
      this.searchParams.search.end_date = this.paramsData.end_date
      let res = await this.$store.dispatch('courseScheduleAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showStudentsModal(item) {
      this.modalData = item
      this.studentsVisible = true
    },
    hideStudentsModal(type) {
      if (type === 1) {
        
      }
      this.studentsVisible = false
    },

    showTeacherModal(item) {
      this.modalData = item
      this.teacherVisible = true
    },
    hideTeacherModal(type) {
      if (type === 1) {
        
      }
      this.teacherVisible = false
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList();
    },
    async toExport(){
      this.searchData.course_class_id = this.selectedRowKeys
      this.exportLoading = true
      let exportData = {
          search:this.searchData,
          sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('courseScheduleExportAction', exportData)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `课程排期.${moment().format('YYYY.MM.DD')}.xls`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    },
    toCancel(){
      let that = this
      this.$confirm({
          title: `确定取消这些课程吗?`,
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
          return new Promise(async (resolve, reject) => {
              let res = await that.$store.dispatch('courseScheduleCancelCourseAction', { selection: that.selectedRowKeys })
              if (res) {
                  that.selectedRowKeys = [];
                  that.$message.success('操作成功!')
                  that.getList()
                  resolve(res)
              }
          }).catch(error => console.log(error))
          }
      })
    },
    showTimeModal(){
      this.isNextDay = true
    },
    hideTimeModal(val){
      if(val === 1){
        this.selectedRowKeys = [];
        this.$message.success('操作成功!')
        this.getList()
      }
      this.isNextDay = false
    },
    showAdjustModal(){
      this.isAdjustModal = true
    },
    hideAdjustModal(val){
      if(val === 1){
        this.selectedRowKeys = [];
        this.$message.success('操作成功!')
        this.getList()
      }
      this.isAdjustModal = false
    }
  }
}
</script>
